dmx.Component('scheduler', {

  initialData: {
    running: false,
    percent: 0,
  },

  attributes: {
    delay: {
      type: Number,
      default: 60,
    },

    unit: {
      type: String,
      default: 'seconds',
      enum: ['miliseconds', 'seconds', 'minutes', 'hours', 'days'],
    },

    noprogress: {
      type: Boolean,
      default: false,
    },

    norepeat: {
      type: Boolean,
      default: false,
    },

    noload: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    start () {
      this._start();
    },

    stop () {
      this._stop();
    },
  },

  events: {
    tick: Event,
  },

  render: false,

  init () {
    if (!this.props.noload) {
      this._start();
    }
  },

  destroy () {
    this._stop();
  },

  _start () {
    this.set('running', true);
    this._startTime = Date.now();
    this._tick();
  },

  _stop () {
    clearTimeout(this._timer);
    this.set('running', false);
    this.set('percent', 0);
  },

  _tick () {
    if (!this.data.running) {
      return;
    }

    if (this.props.noprogress) {
      this.dispatchEvent('tick');

      if (!this.props.norepeat) {
        this._timer = setTimeout(() => this._tick(), this._delay());
      }
    } else {
      let elapsed = Date.now() - this._startTime;
      let total = this._delay();

      if (elapsed >= total) {
        this.set('percent', 100);

        this.dispatchEvent('tick');

        if (this.props.norepeat) {
          this._stop();
        } else {
          this._start();
        }
      } else {
        this.set('percent', Math.ceil((100 * elapsed) / total));
        requestAnimationFrame(() => this._tick());
      }
    }
  },

  _delay () {
    switch (this.props.unit) {
      case 'miliseconds':
        return this.props.delay;
      case 'minutes':
        return this.props.delay * 60000;
      case 'hours':
        return this.props.delay * 3600000;
      case 'days':
        return this.props.delay * 86400000;
      default:
        // default seconds
        return this.props.delay * 1000;
    }
  },
  
});
